/* CSS Formatting for all Course Project Pages */
.about-content {
  max-width: 98%;
  display: flex;
  font-size: 6rem;
}

.feature-list {
  padding: 1%;
  display: flexbox;
}

.subheader {
  font-weight: bold;
  padding-top: 1rem;
  font-size: 1.5rem;
  color: black;
  display: flex;
}

.about-grid {
  font-size: 2rem;
}

.attribution {
  padding-top: 10px;
  font-size: 18px;
}